<template>
  <v-card
    elevation="0"
    class="rounded-lg item-card"
    :maxWidth="maxWidth"
    @click="$router.push({ name: 'pet-detail', params: { id: pet.id } })"
  >
    <v-btn v-if="featured" icon absolute right text style="z-index:1;top:-5px;">
      <img src="@/components/svg/icon/featured.svg" />
    </v-btn>

    <v-img
      cover
      center
      :id="pet.id"
      :height="height"
      :src="pet.media[0]"
      :lazy-src="placeholderImage"
      class=" rounded-lg white--text align-end"
      gradient="180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 61.66%, #000000 100%"
    >
      <div class="pa-3">
        <p class="title">{{ pet.name | trim(10) }}</p>
        <p class="white--text subtitle">
          {{ pet.race ? pet.race.name : "--" }}, {{ pet.age }}
        </p>
      </div>

      <!-- image placeholder -->
      <template v-slot:placeholder>
        <loader loading size="30" height="100%"> </loader>
      </template>
    </v-img>
  </v-card>
</template>

<script>
import placeholderImage from "@/mixin/placeholder-image";

export default {
  name: "pet-box",
  mixins: [placeholderImage],
  props: {
    pet: {
      type: Object,
      required: true
    },

    // cardOptions
    maxWidth: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "210px"
    },
    featured: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped></style>

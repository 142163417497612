<template>
  <v-expand-transition>
    <v-card
      v-if="show"
      tile
      dark
      elevation="0"
      color="dark-blue mt-3"
      class="rounded-lg list-card pl-3"
      height="185"
    >
      <v-btn
        absolute
        dark
        fab
        style="right:8px;top:8px;"
        color="#0000004d"
        @click="$emit('close')"
        height="20"
        width="20"
      >
        <v-icon dark x-small>mdi-close</v-icon>
      </v-btn>

      <v-card-text class="pt-6">
        <div class="card-content">
          <h3 class="white--text">
            {{ $vuetify.lang.t("$vuetify.hi_human") }}
          </h3>

          <p>
            {{ $vuetify.lang.t("$vuetify.are_you_ready_to_be_my_new_family") }}
          </p>

          <span
            class="lets-explore"
            v-ripple
            @click="$router.push({ name: 'listing' })"
          >
            <v-img
              src="@/components/svg/icon/listing.svg"
              contain
              center
              height="14px"
            ></v-img>

            <span class="dark-blue--text">{{
              $vuetify.lang.t("$vuetify.lets_explore")
            }}</span>
          </span>
        </div>
      </v-card-text>
      <img src="@/assets/images/paws-legs.svg" class="paw-image" />
    </v-card>
  </v-expand-transition>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.rounded-lg {
  border-radius: 20px !important;
  overflow: hidden;
}
.card-content {
  max-width: 60%;

  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    margin-bottom: 3px;
  }

  > p {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  }

  .lets-explore {
    background: #fff;
    border-radius: 20px;
    padding: 8px 10px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;

    span {
      font-weight: 500;
      font-size: 10px;
      line-height: 17px;
    }
  }
}

.paw-image {
  position: absolute;
  bottom: -5px;
  right: -1px;
}

.list-card {
  &::v-deep {
    .v-card__text {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
</style>

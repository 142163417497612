<template>
  <div class="card">
    <v-card
      elevation="0"
      class="card--item"
      style="padding:1rem;"
      @click="$router.push({ name: 'pet-detail', params: { id: pet.id } })"
    >
      <v-img
        cover
        center
        height="auto"
        max-width="100%"
        :src="pet.media[0]"
        class=" rounded-lg white--text align-end"
        :lazy-src="placeholderImage"
      >
        <template v-slot:placeholder>
          <loader loading size="30" height="100%"> </loader>
        </template>
      </v-img>
    </v-card>
    <div class="d-flex align-items-center pet-detail">
      <div class="pet-info">
        <p class="title">{{ pet.name }}</p>
        <p class=" caption">
          {{ pet.race ? pet.race.name : "--" }}, {{ pet.age }}
        </p>
      </div>

      <v-spacer />
      <v-btn icon @click="$emit('toggle-like', pet.id)">
        <v-icon :color="pet.liked ? 'error' : ''">
          {{ `mdi-${!pet.liked ? "heart-outline" : "heart"}` }}
        </v-icon>
      </v-btn>
    </div>
    <div class="card--divider light-bg"></div>
  </div>
</template>

<script>
import placeholderImage from "@/mixin/placeholder-image";

export default {
  mixins: [placeholderImage],
  name: "pet-box",
  props: {
    pet: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.card--divider {
  height: 5px;
  width: 100%;
}

.pet-detail {
  padding: 0 1rem 1rem 1rem;
  .pet-info {
    padding-left: 1px;
    p {
      margin: 0;
    }

    p.title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #232323;
    }

    p.caption {
      font-size: 14px;
    }
  }
}
</style>

<template>
  <AppPage>
    <template v-slot:app-bar>
      <v-expand-x-transition>
        <div v-if="!searchMode" class="d-flex" style="width:100%">
          <v-btn icon @click="openFilter">
            <v-img
              src="@/components/svg/icon/filter.svg"
              height="24"
              contain
            ></v-img>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn icon @click="activeSearchMode">
            <v-icon color="black">mdi-magnify</v-icon>
          </v-btn>

          <v-btn icon @click="$router.push({ name: 'listing' })">
            <v-img
              src="@/components/svg/icon/listing.svg"
              contain
              height="25"
            ></v-img>
          </v-btn>
        </div>

        <v-form
          v-else
          @submit.prevent="applyFilter"
          style="width:100%"
          class="d-flex align-items-center"
        >
          <v-text-field
            v-model="keyword"
            hide-details
            dense
            rounded
            filled
            type="search"
            ref="searchField"
            clear-icon="mdi-close-circle"
            @click:clear="clearSearchMode"
            :clearable="isCordova"
            :placeholder="$vuetify.lang.t('$vuetify.search_pet')"
          ></v-text-field>

          <span class="primary--text ml-3" @click="clearSearchMode">{{
            $vuetify.lang.t("$vuetify.cancel")
          }}</span>
        </v-form>
      </v-expand-x-transition>
    </template>

    <v-container ref="container">
      <CategoryList
        :key="categoryId"
        class="mb-0"
        v-model="categoryId"
        @selected="applyFilter"
      />
      <ListingCard :show="showListingCard" @close="showListingCard = false" />
    </v-container>

    <FeaturedPets />

    <PetList />
    <div class="page-end" v-intersect="infiniteScrolling"></div>
  </AppPage>
</template>

<script>
import CategoryList from "@/components/pet-category-list/category-list";
import ListingCard from "@/components/cards/view-listing-card";
import FeaturedPets from "@/components/pet-list/featured-pets";
import PetList from "@/components/pet-list/pet-listing";

import { createNamespacedHelpers, mapState } from "vuex";
const petModule = createNamespacedHelpers("pet");

export default {
  name: "pet-list",
  components: {
    CategoryList,
    ListingCard,
    FeaturedPets,
    PetList,
  },
  data() {
    return {
      showListingCard: true,
      searchMode: false,
    };
  },
  computed: {
    ...mapState(["isCordova"]),
    ...petModule.mapState(["loading", "hasNext", "filter"]),
    categoryId: {
      get() {
        return this.filter.categoryId;
      },
      set(val) {
        this.UPDATE_PET_FILTER({ categoryId: val });
      },
    },
    keyword: {
      get() {
        return this.filter.keyword;
      },
      set(val) {
        this.UPDATE_PET_FILTER({ keyword: val });
      },
    },
  },
  methods: {
    ...petModule.mapActions(["FETCH_PET_LIST", "FETCH_FEATURED_PETS"]),
    ...petModule.mapMutations([
      "UPDATE_PET_FILTER",
      "SET_PET_PAGINATION",
      "CLEAR_PETS_LIST",
    ]),
    openFilter() {
      this.$store.commit("popup/SET_SHEET", {
        showSheet: true,
        sheetComponent: "filter",
      });
    },
    activeSearchMode() {
      this.searchMode = true;
      this.$nextTick(() => {
        const search = this.$refs.searchField;
        search.focus();
      });
    },
    infiniteScrolling(entries, observer, isIntersecting) {
      if (this.hasNext && !this.loading && isIntersecting) {
        this.FETCH_PET_LIST();
      }
    },
    applyFilter() {
      if (this.$refs.searchField) {
        this.$refs.searchField.blur();
      }
      this.CLEAR_PETS_LIST();
      this.FETCH_PET_LIST();
    },
    clearSearchMode() {
      this.searchMode = false;
      this.keyword = "";
      this.applyFilter();
    },
  },
  watch: {},
  created() {
    this.FETCH_FEATURED_PETS();
  },
  beforeDestroy() {
    this.CLEAR_PETS_LIST();
  },
};
</script>

<style lang="scss" scoped></style>

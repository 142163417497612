<template>
  <div>
    <v-container class="pt-0">
      <h3 class="d-flex align-items-center pt-2">
        {{ $vuetify.lang.t("$vuetify.featured_pet") }}
        <v-icon color="golden" class="ml-3">mdi-star</v-icon>
      </h3>
    </v-container>

    <div class="pet--list" v-if="!featuredLoading">
      <template v-if="featurePets.length">
        <PetBox
          class="pet--list-item"
          max-width="180px"
          v-for="pet in featurePets"
          :key="pet.id"
          :pet="pet"
          featured
        />
      </template>

      <p v-else class="subtitle-1 text-center w-100">
        {{ $vuetify.lang.t("$vuetify.no_feature_pet") }}
      </p>
    </div>

    <v-sheet v-else class="pa-3 pet--list">
      <v-skeleton-loader
        class="pet--list-item"
        v-for="n in 2"
        :key="n"
        type="card"
        width="180"
      ></v-skeleton-loader>
    </v-sheet>
  </div>
</template>

<script>
import PetBox from "@/components/cards/pet-box-card";

import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("pet");
export default {
  components: {
    PetBox,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["featurePets", "featuredLoading"]),
  },
};
</script>

<style lang="scss" scoped>
.pet--list {
  display: flex;
  align-items: center;
  overflow: auto;

  &-item {
    margin-left: 1rem;

    &:last-child {
      margin-right: 1rem;
    }
  }
}
</style>

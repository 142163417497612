<template>
  <div>
    <v-container class="pb-0 ">
      <h3 class="d-flex align-items-center pt-1 relative">
        {{ $vuetify.lang.t("$vuetify.all_listings") }}
        <!-- ({{ petList.length }}) -->
        <v-spacer />
        <v-btn :disabled="loading" icon @click="TOGGLE_GRID_VIEW">
          <img :src="viewIcon" alt="grid-icon" />
        </v-btn>
      </h3>
    </v-container>

    <div
      v-if="petList.length > 0"
      class="pet--list"
      :class="`${!IS_GRID_VIEW && 'list-view'}`"
    >
      <component
        v-for="pet in petList"
        :key="pet.id"
        :is="petCardComponent"
        :pet="pet"
        :height="gridHeight"
        @toggle-like="PET_FAVORITE_HANDLER"
        class="pet--list-item"
      />
    </div>

    <v-sheet
      v-if="loading"
      class="pa-3 pet--list"
      :class="`${!IS_GRID_VIEW && 'list-view'}`"
    >
      <v-skeleton-loader
        class="pet--list-item"
        v-for="n in 2"
        :key="n"
        type="card"
      ></v-skeleton-loader>
    </v-sheet>

    <div class="text-center my-5" v-if="!loading && petList.length === 0">
      <p class="subtitle-1">{{ $vuetify.lang.t("$vuetify.no_pet_found") }}</p>
    </div>
  </div>
</template>

<script>
import PetBoxCard from "@/components/cards/pet-box-card";
import PetListCard from "@/components/cards/pet-list-card";

import { createNamespacedHelpers } from "vuex";
const petModule = createNamespacedHelpers("pet");
export default {
  data() {
    return {};
  },
  computed: {
    ...petModule.mapGetters(["IS_GRID_VIEW"]),
    ...petModule.mapState(["petList", "loading"]),
    viewIcon() {
      // return !this.IS_GRID_VIEW ? "mdi-view-grid" : "mdi-view-stream";
      return this.IS_GRID_VIEW
        ? require("@/components/svg/icon/grid-box.svg")
        : require("@/components/svg/icon/grid-list.svg");
    },
    petCardComponent() {
      return this.IS_GRID_VIEW ? PetBoxCard : PetListCard;
    },
    gridHeight() {
      return this.IS_GRID_VIEW ? "180" : "250";
    },
  },
  methods: {
    ...petModule.mapMutations(["TOGGLE_GRID_VIEW"]),
    ...petModule.mapActions(["PET_FAVORITE_HANDLER"]),
  },
};
</script>

<style lang="scss" scoped>
.pet--list {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 0 0.5rem;

  &-item {
    margin: 0.5rem;
  }

  &.list-view {
    grid-template-columns: 100%;
    margin: 0;

    .pet--list-item {
      margin: 0;

      &:first-child {
        &::v-deep {
          .card--item {
            padding-top: 10px !important;
          }
        }
      }
    }
  }
}
</style>
